import React from 'react';

import { useOptimizeActivate } from '../../hooks/useOptimizeActivate';
import { useOptimizeCallback } from '../../hooks/useOptimizeCallback';

const AboutExperiment: React.FC = () => {
  useOptimizeActivate();
  const [variant] = useOptimizeCallback('g3BpbHOYTXSzCfhxamK4YQ');

  switch (variant) {
    case '1':
      return (
        <p>
          Lorem ipsum dolor amet blue bottle master cleanse lyft sartorial palo santo cray. Skateboard sriracha deep v
          austin, freegan raw denim food truck marfa hella godard leggings tote bag art party organic semiotics. Hot
          chicken heirloom VHS bushwick, williamsburg godard lo-fi wolf four loko. Affogato semiotics vegan dreamcatcher
          taxidermy aesthetic fanny pack yr poutine cray locavore vinyl knausgaard chicharrones 8-bit. Lomo literally
          venmo, man bun chia succulents air plant ramps umami poke fashion axe food truck. Meggings fam pour-over irony
          pop-up shoreditch, tacos art party occupy cardigan. Umami tattooed selvage echo park pop-up hashtag PBR&B
          actually health goth.
        </p>
      );
    case '0':
    default:
      return (
        <p>Oh. You need a little dummy text for your mockup? How quaint. I bet you’re still using Bootstrap too…</p>
      );
  }
};

export default AboutExperiment;
