import * as React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import AboutExperiment from '../components/AboutExperiment';

const About: React.FC = () => (
  <Layout>
    <Container>
      <h1>About</h1>
      <AboutExperiment />
    </Container>
  </Layout>
);

export default About;
